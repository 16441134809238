import React from 'react';
import i18n from '../i18n';
export default class Services extends React.Component {
    simulateClick(e) {
        console.log(e)
  }
  componentDidMount() {
      window.jQuery("#filters #first").trigger('click');
  }
    render(){
        return(
            <section className="page-section" id="services" style={{overflow:'hidden', borderTop: '1px solid red', minHeight: '90vh'}}>
              <div className="container" style={{minHeight: '400px'}}>
                <div className="text-center">
                  <h2 className="section-heading text-uppercase">{i18n.t('Services')}</h2>

                </div>
                {/* Privātpersonas */}
                <div id="posts col-xs-12"  className="row no-gutter" style={{marginLeft: '10px', marginTop: '10px', paddingTop: '5%'}}>
                  <div className="col-lg-4 col-sm-12 mb-3 col-xs-12"  style={{textAlign: 'center'}}>
                  <div className="icon">
                    <i className="fas fa-map-marker-check" />
                    </div>
                    <h4 id="servicesItem">{i18n.t('SingleLoc')}</h4>
                 <p>{i18n.t('SingleText')}</p>
                  </div>
                  <div className="col-lg-4 col-sm-12 mb-3 col-xs-12" style={{textAlign: 'center'}}>
                  <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <h4 id="servicesItem">{i18n.t('ManyLoc')}</h4>
                 <p>{i18n.t('ManyText')}</p>
                  </div>
                  <div className="col-lg-4 col-sm-12 mb-3 col-xs-12" style={{textAlign: 'center'}}>
                  <div className="icon">
                    <i className="fas fa-globe" />
                  </div>
                  <h4 id="servicesItem">{i18n.t('CentralSys')}</h4>
               <p>{i18n.t('CentralText')}</p>
                  </div>




                  {/* INOVĀCIJAS */}


              </div>
              </div>
            </section>
        )
    }
}
