import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import i18n from '../i18n';
export default class Partners extends React.Component {
    render(){
        return(
            <section className="page-section bg-light" id="partners" style={{height: '30% !important'}}>
                <div className="container" style={{textAlign: '-webkit-center'}}>
                  <div className="text-center">
                    <h2 className="section-heading text-uppercase" style={{top: 0}}>{i18n.t('Partners')}</h2>
                  </div>
                  <OwlCarousel
                      items={1}
                      loop
                      autoplaySpeed={700}
                      navSpeed={700}
                      autoplay
                      autoplayHoverPause
                      autoplayTimeout={4000}
                      nav
                      navText={["<i class='far fa-chevron-left fa-2x'></i>","<i class='far fa-chevron-right fa-2x'></i>"]}
                      ref={(el) => { this.slider = el; }}
                  >

                    {/* Indicators */}
                    {/* The slideshow  1*/}
                    <div className="row" style={{alignItems: "center"}}>
                    <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12" >
                    <div className="portfolio-item" style={{marginTop: '45px'}}>

                        <img src="partners/bitePartner.png" style={{width: '12em', height: '70px', maxHeight: '70px', objectFit: 'scale-down'}} alt="links" />

                    </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12" >
                      <div className="portfolio-item" style={{marginTop: '45px'}}>

                          <img src="partners/dormakabaPartner.png" style={{width: '13em', height: '70px', maxHeight: '70px', objectFit: 'scale-down'}} alt="links" />

                      </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12" >
                        <div className="portfolio-item" style={{marginTop: '30px'}}>

                            <img src="partners/geovisionPartner.png" style={{width: '12em', height: '70px', maxHeight: '70px', objectFit: 'scale-down'}} alt="links" />

                        </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12" >
                          <div className="portfolio-item" style={{marginTop: '45px'}}>

                              <img src="partners/innovaPartner.png" style={{width: '12em', height: '70px', maxHeight: '70px', objectFit: 'scale-down'}} alt="links" />

                          </div>
                          </div>



                      </div>
                      <div className="row"  style={{alignItems: "center"}}>
                      <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12" >
                      <div className="portfolio-item" style={{marginTop: '45px'}}>

                          <img src="partners/lbpPartner.png" style={{width: '12em', height: '70px', maxHeight: '70px', objectFit: 'scale-down'}} alt="links" />

                      </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12" >
                        <div className="portfolio-item" style={{marginTop: '45px'}}>

                            <img src="partners/supremaPartner.png" style={{width: '12em', height: '70px', maxHeight: '70px', objectFit: 'scale-down'}} alt="links" />

                        </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12" >
                          <div className="portfolio-item" style={{marginTop: '45px'}}>

                              <img src="partners/acrelec.png" style={{width: '12em', height: '70px', maxHeight: '70px', objectFit: 'scale-down'}} alt="links" />

                          </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12" >
                            <div className="portfolio-item" style={{marginTop: '45px'}}>

                                <img src="partners/zuchettiPartner.png" style={{width: '12em', height: '70px', maxHeight: '70px', objectFit: 'scale-down'}} alt="links" />

                            </div>
                            </div>



                        </div>
                  </OwlCarousel>

              </div>
            </section>
        )
    }
}
