import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import i18n from '../i18n';
const modules = [
  {
    prefix: "WH",
    title: "Time & Attendance",
    content: 'Darba laika uzskaite',
    logo: "assets/img/T&A.png",
    url: '/timeattendance'
  },
  {
    prefix: "WH",
    title: "Shifting",
    content: 'Maiņu plānošana',
    logo: "assets/img/shifting.png",
    url: '/shifting'
  },
  {
    prefix: "WH",
    title: "Assets",
    content: 'Pamatlīdzekļu pārvalde',
    logo: "assets/img/Assets.png",
    url: '/assets'
  },
  {
    prefix: "WH",
    title: "Fleet",
    content: 'Transportlīdzekļu uzskaite',
    logo: "assets/img/Fleet.png",
    url: '/fleet'
  },
  {
    prefix: "WH",
    title: "OEE",
    content: 'Iekārtu efektivitāte',
    logo: "assets/img/OEE.png",
    url: '/oujeje'
  },
  {
    prefix: "WH",
    title: "GKeeper",
    content: 'Transporta piekļuves kontrole',
    url: '/gatekeeper',
    logo: "assets/img/GKeeper.png"
  },
  {
    prefix: "WH",
    title: "GATE",
    content: 'Stāvvietu loģistikas pārvalde',
    logo: "assets/img/GATE.png",
    url: '/gate'
  },
  {
    prefix: "WH",
    title: "Access",
    logo: "assets/img/Access.png",
    content: 'Piekļuves kontrole',
    url: '/access'
  },
  {
    prefix: "WH",
    title: "Cloud",
    content: 'Datu arhivēšana',
    url: '/cloud',
    logo: "assets/img/Cloud.png",

  },
  {
    prefix: "WH",
    title: "Topics",
    content: 'Iekšējā saziņa',
    url: '/chatroom',
    logo: "assets/img/Topics.png",

  },
  {
    prefix: "WH",
    title: "Tasks",
    content: 'Darba procesu uzskaite',
    url: '/dpus',
    logo: "assets/img/Tasks.png",

  },
  {
    prefix: "WH",
    title: "Hardware",
    content: 'Iekārtas',
    url: '/hardwares',
    logo: "assets/img/hardware.png",

  },
];

export default class Experience extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: 3,
            slideBy: 3,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });

      if (window.innerWidth < 1198) {
          this.setState({
              items: 2,
              slideBy: 2,
          })
      } else {
            this.setState({
                items:3 ,
                slideBy: 3,
            })}
    }
    componentDidMount(){
         window.addEventListener('resize', this.updateWindowDimensions);
         this.updateWindowDimensions();
    }
    render(){
        const { items, slideBy } = this.state;
        return(
            <section className="page-section bg-light" id="portfolio">
              <div className="container">
              <div className="text-center">
                <h2 className="section-heading text-uppercase"><span style={{ color: '#dc191e' }}>WORKHOUR</span> MODUĻI</h2>
              </div>
              <div class="row">
              <div id="filters" className="filters row text-center button-group col-md-12">
                {modules && modules.map((each) => {
                  return (
                    <div className="portfolio-item col-xs-12 col-sm-6 col-md-3" style={{ height: 'auto'}}>
                        <div class="img">
                          <img src={each.logo} alt="Portfolio Item" style={{ filter: 'brightness(.9)',  borderRadius: '10px 10px 0px 0px'}}/>
                        </div>
                      <div className="portfolio-caption" style={{ backgroundColor: '#242830', borderRadius: '0px 0px 10px 10px'}}>
                        <div className="portfolio-caption-heading">
                          <span style={{ color: '#dc191e', fontSize: '1rem', fontWeight: 'bold' }}>
                            {each.prefix}
                            {' '}
                          </span>
                          <span style={{ color: 'white', fontSize: '1rem',}}>
                            {each.title}
                          </span>
                          <br />
                          <span style={{ fontSize: '.9rem', color: 'white' }}>
                            {each.content}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              </div>
              </div>
            </section>
        )
    }
}
