import React from 'react';

export default class Footer extends React.Component {
    render(){
      const { bgColor, color} = this.props;
        return(
            <footer className="footer py-4 m-0" style={{ backgroundColor: bgColor && bgColor, color: color && color}}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-4 text-lg-left">Copyright © Datu tehnoloģiju grupa 2020</div>
                  <div className="col-lg-4 my-3 my-lg-0">
                  <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.linkedin.com/organization-guest/company/datu-tehnolo%C4%A3iju-grupa?challengeId=AQFy-a0OjBrO4AAAAXNWeysJtqoOTpbCvh_q4BVBzDK1wy6-Cd1ADkdMLDpahjhgSr3V1WG3N5PDMCVr0el2bjgrZDVkv1NDUQ&submissionId=5533b2dd-582a-2216-10ec-10733bc0d069" target="_blank"><i className="fab  fa-linkedin-in" /></a>
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.youtube.com/channel/UChQTFh6KiYLoowbxc5FiMkw" target="_blank"><i className="fab fa-youtube" /></a>
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.facebook.com/datutehnologijugrupa/" target="_blank"><i className="fab fa-facebook" /></a>
                  </div>
                </div>
              </div>
            </footer>
        )
    }
}
