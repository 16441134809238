import React from 'react';
import '../../App.css';
import i18n from '../../i18n';

import { Link } from 'react-router-dom'
import Nav from '../../components/nav.js';
import Footer from '../../components/footer.js'
import CookieConsent from "react-cookie-consent";
import { withNamespaces } from 'react-i18next';
const posts = [
  {
    title: 'Cik maksā darba laika uzskaites sistēma?',
    content: 'Teksts',
    date: '07.09.2021',
    // label: 'WH Time & Attendance',
    img: 'assets/blog/blog1.png',
    url: '/blog/dlus-cena',
  },
  {
    title: `DTG sadarbībā ar RTU uzsāk ERAF projektu`,
    content: 'Teksts',
    date: '01.01.2022',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf',
    img: 'assets/blog/blog-2.png',
  },
  {
    title: `Projekta "Platforma Covid-19 drošai darba videi" (identifikācijas nr 1.1.1.1/21/A/011) otrā ceturkšņa pārksats.`,
    content: 'Teksts',
    date: '01.07.2022',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf/secondQuarter',
    img: 'assets/blog/blog-2.png',
  },
]
class Blog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    render(){
        const {  dati, loading, items } = this.state;
          return (
            <>
              <div className="blog container-fluid col-md-12" id="blog" style={{ height: '100vh'}}>
              <Nav />
                  <div style={{overflow: 'hidden', justifyContent: 'center'}} className="row col-md-12">
                  {posts.map((each) => {
                    return (
                      <Link to={each.url} className="blog-item col-xs-12 col-sm-12 col-md-12 col-lg-3" style={{ height: 'auto', borderRadius: '10px'}}>
                          <div class="img" style={{ height: '250px', width: 'auto' }}>
                            <img src={each.img} alt="Portfolio Item" style={{ filter: 'brightness(.9)',  borderRadius: '10px 10px 0px 0px', maxWidth: '100%', maxHeight: '100%', height: '100%', margin: 'auto', display: 'block'}}/>
                          </div>
                          <div className="p-2 pb-0 mb-0 pt-2 col-md-12 d-flex" style={{ backgroundColor: 'rgb(36, 40, 48)', height: '100%'}}>
                            <div className="blog-caption pt-4 col-md-12  " style={{ backgroundColor: 'rgb(36, 40, 48)' }}>
                              <div className="blog-caption-heading text-center">
                                {each.title}
                              </div>
                              <div className="text-right pt-0 px-0 mb-0" style={{ backgroundColor: 'rgb(36, 40, 48)' }}>
                                {each.date}
                              </div>
                            </div>
                          </div>
                      </Link>
                    )
                  })}
                    </div>
              </div>
              </>
          );
        }
    }



export default withNamespaces()(Blog);
