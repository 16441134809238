import React from 'react';
import '../App.css';
import Nav from '../components/nav.js';
import Footer from '../components/footer.js'
import CookieConsent from "react-cookie-consent";
import { withNamespaces } from 'react-i18next';
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    render(){
        const {  dati, loading, items } = this.state;


            return (
                <div className="App">
                    <div style={{overflow: 'hidden'}}>
                    <CookieConsent overlay buttonText="Apstiprināt" >
                      Šī vietne izmanto sīkdatnes, lai uzlabotu lietošanas pieredzi un optimizētu tās darbību. <a href="https://privatums.dtg.lv" target="_blank">Lasīt vairāk</a>
                    </CookieConsent>
                        <Nav />
                        <Footer />
                    </div>
                </div>
            );
        }
    }



export default withNamespaces()(App);
