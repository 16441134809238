import React from 'react';
import i18n from '../i18n';
export default class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: 3,
        };
        this.startIThrown = this.startIThrown.bind(this);
    }
    startIThrown(){
      document.location = 'waze://?ll=56.98000523%2C24.11761880&navigate=yes';
      setTimeout(() => {
        if(window.confirm('Jūsu ierīcē nav ielādēts waze, vai vēlaties atvērt iekš WEB?')){
          window.open('https://waze.com/ul?ll=56.98000523%2C24.11761880&navigate=yes');
        }
    }, 5000);
    }
    render(){
        return(
            <section className="dark-bg text-center section-padding contact-wrap" id="contact" style={{height: '100%', padding: '20px'}}>
              <div className="container">
                <div className="row contact-details">
                  <div className="col-md-4 col-sm-12">
                  <a  href="#" onClick={()=> {this.startIThrown()}} id="driveto" target="_blank">
                    <div className="light-box box-hover" style={{marginBottom: '5%'}}>
                      <h2 id="credits"><i className="fas fa-compass" /><span>{i18n.t('Adress')}</span></h2>
                      <p> Rīga, Ganību dambis 24A- 52, LV - 1005</p>
                    </div>
                    </a>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="light-box box-hover" style={{marginBottom: '5%'}}>
                      <h2 id="credits"><i className="fas fa-mobile-alt" /><span>{i18n.t('Phone')}</span></h2>
                        <p><a href="tel:66055001" id="mailto">+371 66055001</a></p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="light-box box-hover" style={{marginBottom: '5%'}}>
                      <h2 id="credits"><i className="fa fa-paper-plane" /><span>{i18n.t('Email')}</span></h2>
                      <p><a href="mailto:sales@dtg.lv" id="mailto">sales@dtg.lv</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        )
    }
}
