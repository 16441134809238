import React from 'react';
import '../../App.css';
import i18n from '../../i18n';

import { Link } from 'react-router-dom'
import Team from '../../components/team.js';
import Nav from '../../components/nav.js';

import Footer from '../../components/footer.js'
import CookieConsent from "react-cookie-consent";
import { withNamespaces } from 'react-i18next';
const posts = [
  {
    title: 'Darba laika uzskaites sistēma',
    content: 'Teksts',
    date: '07.09.2021',
    // label: 'WH Time & Attendance',
    img: 'assets/blog/blogpost.png',
  },

]
class SinglePost extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    render(){
        const {  dati, loading, items } = this.state;
          return (
            <>
              <div className="blog container-fluid col-md-12" id="blog" style={{ height: '100%'}}>
              <Nav />
                  <div style={{overflow: 'hidden', justifyContent: 'center'}} className="row col-md-12">
                  <div to="/blog/dlus-cena" className="blog-single col-md-12" style={{ borderRadius: 15, backgroundColor: 'rgb(36, 40, 48) !important'}}>

                  <div className="img col-md-12" style={{ width: '65%' }}>
                     <img src={posts[0].img} alt="Portfolio Item" />
                  </div>
                  <div className="col-md-12 mt-3">
                    <h3> {posts[0].title} </h3>
                  </div>
                  <div className="col-md-8 mt-2">

                  <p>Darba laika uzskaite pati par sevi nav nekāds jaunums. Pirmās liecības par šādas sistēmas pielietojumu ir atrodamas pat 1888.gadā Ņujorkā, ASV kādā juvilierizstrādājumu salonā. Protams, tā laika sistēma bija ļoti primitīva. Ir iekārta, kurā iestrādāts pulkstenis un mehānisms, kas uz katra individuāla darbinieka kartona kartiņas “iesit” laiku, kurā kartiņa tika ielikta iekārtā. Pieļauju, ka visi esam kaut filmās redzējuši šādu iekārtu, iespējams, ka pat kāds ir paspējis tādu lietot.</p>
                  <p>Kaut arī tehnoloģijas, kas veic uzskaiti ir mainījušās, iemesls, kāpēc tā tiek veikta ir tas pats. <b><u>Vai darbinieks ir darbā, vai ieradies laikā un cik stundas mēnesī ir nostrādātas? </u></b></p>

                  <p>Šodien vēl varam pateikt vai kolēģi ir atvaļinajumā, pusdienās (un cik ilgi vēl būs prom), izbraukumā vai strāda attālināti.</p>


                  <p>Vēl nemaz nesākot runāt par sistēmas izmaksām, vēlētos piemest sistēmas radīto naudas ietaupījumu. Pašsaprotamas ir tās pazaudētās 5 minūtes, kuras nokavējam vai nenostrādājam līdz galam. <b><u> Dēļ tām vien uzņēmums ar 20 darbiniekiem ar 5 EUR/h algas likmi zaudē iespaidīgus 300 EUR mēnesī. </u></b> Mazāk pamanāmas, bet tieši tik pat reālas ir administrācijas izmaksas aprēķinu veikšanai kā arī produktivitātes zudumi zaudēto minūšu dēļ.</p>

                  <div className="col-md-12 mt-3">
                    <h3>"<i>Cik tad šāda sistēma man izmaksā? </i>"</h3>
                  </div>
                  <p>Kā jau jebkurš šādas sistēmas piegādātājs, arī mēs precīzāk varam noteikt cenu tikai pēc nelielas klienta intervijas. Būtu ideāli zināt uzņēmuma darbinieku skaitu, objektu skaitu un to, vai ir nepieciešamas reģistracijas iekārtas, bet kopumā, uzņēmums var rēķināties ar aptuvenām ikmēneša izmaksām <b><u>3-5 EUR līmenī par katru sistēmas lietotāju atkarībā no to skaita.</u></b></p>
                  <p>Papildus ir jāpiedomā aprēķinā, kā tiks veikta datu reģistrācija? Te jau situācija ir vienkāršāka, varianti ir divi – veicam laika reģistrāciju ar iekārtu uz vietas vai izmantojot mobilo lietotni. Tātad, tas nozīmē, ka ir opcija strādat ar vai bez iekārtas. </p>
                  <div className="col-md-12 mt-3">
                    <h3><i>Kāda ir starpība?</i></h3>
                  </div>
                  <p>Iekārtas izmantošana galvenokārt nodrošina to, ka tā atbalsta arī durvju atslēgšanas funkciju un iespējama arī piekļuves kotrole, kas ir arī programmatūras pamata funkcionalitātes daļa. Tomēr jārēķinās, ka šis papildinājums prasa nelielu vienreizējo sākuma investīciju sākot no 185 EUR.</p>
                  <p>Ir arī iespēja strādāt bez iekārtas kā arī iespējams pielāgot citus izcenojuma modeļus, kas labāk piemēroti katra uzņēmuma specifikai, bet par to tad labāk individuāli…</p>
                  </div>
                    </div>
                    </div>
                    <Team />
              </div>
              <Footer color="white" bgColor="#242830" />
              </>
          );
        }
    }



export default withNamespaces()(SinglePost);
