import React from 'react';
import i18n from '../i18n';
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next';
 export default class Nav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: 3,
            logo: 'logo.png',
            height: '84px',
        };
        this.changeLanguage = this.changeLanguage.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    changeLanguage(event, lng) {
        console.log('CHANGE')
        i18n.changeLanguage(lng);

    }
    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
      if (window.innerWidth < 1200) {
          this.setState({
              logo: 'logo.png',
              height: '40px'
          })
      } else {
          this.setState({
              logo: 'logo.png',
              height: '84px'
          })
      }
    }
    componentDidMount(){
         window.addEventListener('resize', this.updateWindowDimensions);
         this.updateWindowDimensions();
    }
    render(){
        const { logo, height } = this.state;
        return(
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
              <div className="container">
                <a className="navbar-brand js-scroll-trigger" href="/">
                    <img  src={`assets/img/${logo}`} style={{width: 'auto', marginTop: '10px', objectFit: 'initial', height: `${height}`}} alt="test" />
                </a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Izvēlne
                        <i className="fas fa-bars ml-1" />
                    </button>
                <div className="collapse navbar-collapse" id="navbarResponsive" style={{justifyContent: 'center'}}>
                  <ul id='navUl' className="navbar-nav text-uppercase ml-auto">
                    <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#services">{i18n.t('Services')}</a></li>
                    <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#portfolio">{i18n.t('Experience')}</a></li>
                {/*    <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#about">{i18n.t('About')}</a></li>*/}
                    <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#team">{i18n.t('Team')}</a></li>
                    <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contact">{i18n.t('Contacts')}</a></li>
                    <li className="nav-item"><a className="nav-link js-scroll-trigger" href="/blog">{i18n.t('Blog')}</a></li>


                    <ul id='langs' className="navbar-nav text-uppercase ml-auto">
                    <li className="nav-item" style={{cursor: 'pointer', marginRight: '0rem'}} ><a className="nav-link js-scroll-trigger"  onClick={(event) =>  this.changeLanguage(event, 'lv')}>LV</a></li>
                    <li className="nav-item" style={{cursor: 'pointer', marginRight: '0rem'}}><a  className="nav-link js-scroll-trigger"  onClick={(event) =>  this.changeLanguage(event, 'en')}>ENG</a></li>
                    <li className="nav-item" style={{cursor: 'pointer', marginRight: '0rem'}}><a className="nav-link js-scroll-trigger" onClick={(event) =>  this.changeLanguage(event, 'ru')}>RU</a></li>


                    </ul>
                  </ul>
                </div>
              </div>
            </nav>
        )
    }
}
