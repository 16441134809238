import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import Base from './base/base'
import * as serviceWorker from './serviceWorker';
import CookieConsent from "react-cookie-consent";

ReactDOM.render(
  <>
  <CookieConsent buttonText="Apstiprināt" >
  Šī vietne izmanto sīkdatnes, lai uzlabotu lietošanas pieredzi un optimizētu tās darbību. <a href="https://privatums.dtg.lv" target="_blank">Lasīt vairāk</a>
  </CookieConsent>
    <Base />
    </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
