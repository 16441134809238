import React from 'react';
import './App.css';
import Nav from './components/nav.js';
import Slider from './components/Slider.js';
import Services from './components/services.js'
import Experience from './components/experience.js'
import About from './components/about.js';
import Partners from './components/partners.js';
import Team from './components/team.js';
import Contact from './components/contact.js';
import Footer from './components/footer.js'
import CookieConsent from "react-cookie-consent";
import { withNamespaces } from 'react-i18next';
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    render(){
        const {  dati, loading, items } = this.state;


            return (
                <div className="App">
                    <div style={{overflow: 'hidden'}}>
                      {/* Navigācija */}
                        <Nav />
                      {/* Headeris / Videoklipa vieta*/}
                        <Slider />
                      {/* Pakalpojumi*/}
                        <Services />
                      {/* Portfolio*/}
                        <Experience />
                      {/* Par Mums */}
                      {/* Sadarbības Partneri */}
                      {/* Komanda*/}
                        <Team />
                      {/* Sazināties  */}
                        <Contact />
                      {/* Footer*/}
                         <Footer />

                    </div>
                </div>
            );
        }
    }



export default withNamespaces()(App);
